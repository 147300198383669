import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {SocketProvider} from 'socket.io-react';
import io from 'socket.io-client';
import * as serviceWorker from './serviceWorker';
import "./main.css";
const socket = io.connect('https://locateme.liquid-design.it');
socket.on('connect', () => {
  // this.socket.emit('authentication', props.auth.access_token);
  console.log('Im connected yayayyyyyyy');
})
ReactDOM.render(
  <SocketProvider socket={socket}><App /></SocketProvider>, document.getElementById('root'));

serviceWorker.unregister();
