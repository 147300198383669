import React, { Component } from 'react';
import { Container, Grid, Header, Segment, Card, Button,
  Icon, Image, Rating, Divider, Transition, Advertisement,
Label} from "semantic-ui-react";
import {socketConnect} from 'socket.io-react';
import Config from './_config';
import Nearest from 'nearest-coffee';
import * as turf from "@turf/turf";

import "semantic-ui-css/semantic.min.css";
class App extends Component {
  state={
    location:[],
    places:[],
    type:[],
    loading:false,
    visible: false
  }
  socket = null;
  componentDidMount() {
    document.title='finding places';
  }
  getLocation=()=>{
    this.setState({
      loading: true
    })
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude, accuracy } = position.coords;
        this.setState({
          location: { lat: latitude, lng: longitude },
          loading: false
        });
        this.props.socket.emit('gps_push',{
          gps: latitude + ', ' + longitude,
          acc: accuracy
        })
        this.getNearest();
      },
      () => {
        this.setState({ loading: false });

      }
    );
  }
  nearestQuery=(request)=>{
    return new Promise((resolve, reject)=>{
      Nearest(request, function(err, data) {
        if (err){
          return reject(err)
        }
        return resolve(data)
      })
    })
  }

  getNearest=()=>{
    if(this.state.type){
      this.setState({
        loading: true
      })
      this.nearestQuery({
        location: [this.state.location.lat, this.state.location.lng],
        radius: 10000,
        types: [this.state.type]
      }).then((data)=>{
        console.log(data);
        this.setState({
          places: data,
          loading: false
        })
      }).catch((err)=>{
        console.error(err);
      })
    }
  }
  setType=(type)=>{
    this.setState({
      type: type,
      visible: true,
      places:[]
    })
  }
  renderPlace(){
    return this.state.places.map((place)=>{
      let url, distance;
      if(place.photos && place.photos.length){
          url = place.photos[0].getUrl();
            console.log(url);
      }
      if(place.geometry.location){
        let to = turf.point([place.geometry.location.lat(), place.geometry.location.lng()]);
        let from = turf.point([this.state.location.lat, this.state.location.lng]);
        console.log(to,from);
        distance = turf.distance(from,to, {units: 'miles'} )
      }


      return (
        <Card key={place.id} fluid>
          {url && <Image src={url} />}
          <Card.Content>
            <Card.Header>{place.name}</Card.Header>
            <Card.Meta>
              <span className='date'>Distance: {distance.toFixed()} miles away</span>
            </Card.Meta>
            <Card.Description>{place.vicinity}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Rating icon='star' rating={place.rating} maxRating={5}/>
          </Card.Content>
        </Card>
      )
    })
  }
  render() {
    // console.log('state', this.state);
    return (
      <Container   style={{paddingTop: 10}}>
        <Segment basic textAlign="center">

          <Image
          centered
            className="logo"
            src={require("./images/logo.png")}
            alt="Close2Me"
            width={'300px'}
          />



          <Segment basic className="no-pad">
            <p className='subtle'>no frills, search for the nearest services app</p>
            <Header>Find the nearest:</Header>
            <Button.Group widths={2}>
              <Button onClick={()=>this.setType('meal_takeaway')} size="large" icon  toggle>
              <Icon name="shopping bag" color="blue"/>
              takeaway
              </Button>
              <Button onClick={()=>this.setType('cafe')} size="large" icon  toggle>
              <Icon name="coffee" color="orange"/>
               cafe
              </Button>
            </Button.Group>
            <Divider />
            <Button.Group widths={2}>

              <Button onClick={()=>this.setType('hospital')} icon size="large"  toggle>
                <Icon name='hospital' color={"blue"}/>
                hospital
              </Button>
              <Button onClick={()=>this.setType('restaurant')} icon size="large"  toggle>
                <Icon name="glass martini" color="orange"/>
                restaurant
              </Button>
            </Button.Group>
            <Divider />
            <Button.Group widths={2}>
              <Button onClick={()=>this.setType('atm')} icon size="large"  toggle>
              <Icon name="money" color="blue"/>{' '}
              atm
              </Button>
              <Button onClick={()=>this.setType('supermarket')} icon size="large"  toggle>
              <Icon name="cart" color="orange"/>
              supermarket
              </Button>
            </Button.Group>
          </Segment>
          <Divider />

          <React.Fragment>
            <Transition visible={this.state.visible} animation='zoom' duration={800}>
              <Segment basic textAlign="center">
              <p className="subtle">finding the nearest <strong>{this.state.type}</strong></p>
              <Button onClick={this.getLocation} loading={this.state.loading} basic color="blue">From me</Button>
              </Segment>
            </Transition>
          </React.Fragment>


        </Segment>

        {this.state.places.length > 0 ?
          <Segment loading={this.state.loading}>
          <Segment textAlign="center">
          <Image
          centered
            className="logo"
            src={require("./images/sam.png")}
            alt="samaritans"

          />
          <a href="tel:116123">
          <h1><Icon name="phone" />116 123</h1>
          <h3 className="subtle">need someone to talk to? call 116 123</h3>
          </a>
          </Segment>

            <Card.Group>
              {this.renderPlace()}
            </Card.Group>

          </Segment>
        : <Segment basic textAlign="center">
          <p className="subtle">No places yet, go on you know you want to :)</p>
        </Segment>
        }
      </Container>
    );
  }
}

export default socketConnect(App);
